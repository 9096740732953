// ** JWT Service Import
import JwtServiceForWorkspace from './jwtServiceForWorkspace'

// ** Export Service as useJwt
export default function useJwtServiceForWorkspace(jwtOverrideConfig) {
    const jwt = new JwtServiceForWorkspace(jwtOverrideConfig)

    return {
        jwt
    }
}
