const productionEndpoint = "https://api.acttopus.com/"
const stagingEndpoint = "http://18.158.226.55/"
const localEndpoint = "http://localhost:8080/"

const endpoint = productionEndpoint

const routes = {
  clients: 'clients'
}

// ** Auth Endpoints
export default {
  loginEndpoint: `${endpoint}${routes.clients}/login`,
  registerEndpoint: `${endpoint}${routes.clients}/register`,
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageTokenKeyNameForWorkspace: 'workspaceAccessToken',
  currencyTokenName: 'currency'
}
