// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import user from './user'
import navbar from './navbar'
import layout from './layout'
import loader from './loader'

const rootReducer = combineReducers({
  user,
  navbar,
  layout,
  loader
})

export default rootReducer
