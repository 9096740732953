import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
const newAxios = axios.create()

export default class JwtServiceForWorkspace {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

        // ** Request Interceptor
        newAxios.interceptors.request.use(
            config => {
                // ** Get token from localStorage
                const accessToken = this.getToken()
                const currency = this.getCurrency()

                // ** eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                config.headers.currency = currency
                return config
            },
            error => Promise.reject(error)
        )

        // ** Add request/response interceptor
        newAxios.interceptors.response.use(
            response => response,
            error => {
                // ** const { config, response: { status } } = error
                const { config, response } = error
                const originalRequest = config

                // ** if (status === 401) {
                if (response && response.status === 401 && response.data.error !== 'Unverified Client, A a new email has been sent to your email to verify it') {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true
                        this.refreshToken().then(r => {
                            this.isAlreadyFetchingAccessToken = false

                            // ** Update accessToken in localStorage
                            this.setToken(r.data.accessToken)
                            this.setRefreshToken(r.data.refreshToken)

                            this.onAccessTokenFetched(r.data.accessToken)
                        })
                    }
                    const retryOriginalRequest = new Promise(resolve => {
                        this.addSubscriber(accessToken => {
                            // ** Make sure to assign accessToken according to your response.
                            // ** Check: https://acttopus.ticksy.com/ticket/2413870
                            // ** Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                            resolve(this.newAxios(originalRequest))
                        })
                    })
                    return retryOriginalRequest
                }
                return Promise.reject(error)
            }
        )
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyNameForWorkspace)
    }

    getCurrency() {
        const currency = localStorage.getItem(this.jwtConfig.currencyTokenName)
        if (currency) {
            return currency
        } else {
            return 'EGP'
        }
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyNameForWorkspace, value)
    }

    setRefreshToken(value) {
        localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
    }

    get(endpoint, ...args) {
        return newAxios.get(endpoint, ...args)
    }

    post(endpoint, ...args) {
        return newAxios.post(endpoint, ...args)
    }

    put(endpoint, ...args) {
        return newAxios.put(endpoint, ...args)
    }

    getAll(endpoints) {
        return Promise.all(endpoints)
    }

    delete(endpoint, ...args) {
        return newAxios.delete(endpoint, ...args)
    }

    refreshToken() {
        return newAxios.post(this.jwtConfig.refreshEndpoint, {
            refreshToken: this.getRefreshToken()
        })
    }
}
