// **  Initial State
const initialState = {
  loaderLoading: true
}

const loader = (state = initialState, action) => {
  switch (action.type) {
    case 'SWITCHLOADER':
      return {
        ...state,
        loaderLoading: !state.loaderLoading
      }
    default:
      return state
  }
}

export default loader
