import logo3x from '@src/assets/images/logo/logo@3x.png'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner vh-100'>
      <img className='fallback-logo' src={logo3x} srcSet={`${logo3x} 2x, ${logo3x} 3x`} alt='logo' />
      <div className='loading'>
      </div>
    </div>
  )
}

export default SpinnerComponent
