import history from '@history'


// **  Initial State
const initialState = {
  userData: {},
  clientEmailNeedsVerification: null,
  workspaces: null,
  defualtWorkspace: null,
  showHomeExplainCard: true,
  dashboardCurrency: 'EGP'
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.userData,
        clientEmailNeedsVerification: action?.clientEmailNeedsVerification ? action?.clientEmailNeedsVerification : null,
        [action.config.storageTokenKeyName]: action[action.config.storageTokenKeyName],
        [action.config.storageRefreshTokenKeyName]: action[action.config.storageRefreshTokenKeyName]
      }
    case 'LOGIN_WITH_WORKSPACE':
      const { userData, clientEmailNeedsVerification, config, workspaces } = action.data
      history.push('/')
      return {
        ...state,
        userData,
        clientEmailNeedsVerification: clientEmailNeedsVerification ? clientEmailNeedsVerification : null,
        [config.storageTokenKeyName]: action[config.storageTokenKeyName],
        [config.storageRefreshTokenKeyName]: action[config.storageRefreshTokenKeyName],
        workspaces: [...workspaces]
      }
    case 'LOGOUT':
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {}, ...obj }
    case 'UPDATE_USER':
      return {
        ...state,
        userData: { ...state.userData, ...action.data }
      }
    case 'CLIENT_VERIFICATION_NEEDED':
      return {
        ...state,
        clientEmailNeedsVerification: { ...action.data }
      }
    case 'CLIENT_RESET_PASSWORD_REQUESTED':
      return {
        ...state,
        clientEmailNeedsVerification: { ...action.data }
      }
    case 'UPDATE_USER_VERIFIED':
      return {
        ...state,
        userData: { ...state.userData, ...action.data }
      }
    case 'SET_WORKSPACES':
      return {
        ...state,
        workspaces: [...action.data],
        userData: { ...state.userData, registrationStep: action?.data?.length === 1 ? 3 : null }
      }
    case 'SET_DEFUALT_WORKSPACE':
      return {
        ...state,
        defualtWorkspace: { ...action.data }
      }
    case "REMOVE_WORKSPACES":
      return {
        ...initialState
      }
    case 'SET_SHOW_SAMPLE_DATA':
      return {
        ...state,
        showSampleData: action.data
      }
    case 'SET_SHOW_HOME_EXPLAIN_CARD':
      return {
        ...state,
        showHomeExplainCard: !state.showHomeExplainCard
      }
    case 'SET_DASHBOARD_CURRENCY':
      localStorage.setItem('currency', action.data)
      return {
        ...state,
        dashboardCurrency: action.data
      }
    default:
      return state
  }
}

export default userReducer
