import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import createDebounce from 'redux-debounced'
import thunk from 'redux-thunk'
import useJwt from '@src/auth/jwt/useJwt'
import useJwtForWorkspace from '@src/auth/jwt/useJwtForWorkspace'

import rootReducer from '../reducers/rootReducer'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['loader']
}

// ** init middleware
const middleware = [thunk.withExtraArgument({ useJwt, useJwtForWorkspace }), createDebounce()]
// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)))
const persistor = persistStore(store)


export { persistor, store }